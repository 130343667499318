// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-analyze-your-own-data-js": () => import("./../../../src/pages/analyze-your-own-data.js" /* webpackChunkName: "component---src-pages-analyze-your-own-data-js" */),
  "component---src-pages-automate-repetitive-analyses-js": () => import("./../../../src/pages/automate-repetitive-analyses.js" /* webpackChunkName: "component---src-pages-automate-repetitive-analyses-js" */),
  "component---src-pages-bcc-2020-demo-js": () => import("./../../../src/pages/bcc2020-demo.js" /* webpackChunkName: "component---src-pages-bcc-2020-demo-js" */),
  "component---src-pages-beta-js": () => import("./../../../src/pages/beta.js" /* webpackChunkName: "component---src-pages-beta-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-contact-confirm-js": () => import("./../../../src/pages/contact_confirm.js" /* webpackChunkName: "component---src-pages-contact-confirm-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-custom-tools-js": () => import("./../../../src/pages/custom-tools.js" /* webpackChunkName: "component---src-pages-custom-tools-js" */),
  "component---src-pages-demo-js": () => import("./../../../src/pages/demo.js" /* webpackChunkName: "component---src-pages-demo-js" */),
  "component---src-pages-efficient-lab-environment-js": () => import("./../../../src/pages/efficient-lab-environment.js" /* webpackChunkName: "component---src-pages-efficient-lab-environment-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-pro-welcome-js": () => import("./../../../src/pages/pro-welcome.js" /* webpackChunkName: "component---src-pages-pro-welcome-js" */),
  "component---src-pages-training-and-collaboration-js": () => import("./../../../src/pages/training-and-collaboration.js" /* webpackChunkName: "component---src-pages-training-and-collaboration-js" */),
  "component---src-pages-webinars-js": () => import("./../../../src/pages/webinars.js" /* webpackChunkName: "component---src-pages-webinars-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-docs-js": () => import("./../../../src/templates/docs.js" /* webpackChunkName: "component---src-templates-docs-js" */),
  "component---src-templates-legal-js": () => import("./../../../src/templates/legal.js" /* webpackChunkName: "component---src-templates-legal-js" */)
}

